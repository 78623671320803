/* StudyModal.css */
.start-study-btn {
    font-size: 18px;
    padding: 15px 40px;
    background-color: #1890ff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.study-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
}

.study-option {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 140px;
    font-size: 14px;
}

.start-study-footer {
    text-align: center;
}

/* For tablets and desktops */
@media only screen and (min-width: 768px) {
    .study-options {
        flex-direction: row;
    }
}

/* For mobile */
@media only screen and (max-width: 767px) {
    .study-options {
        flex-direction: column;
        align-items: center;
    }
}