/* Additional CSS to make the Sider span the full height */
.page-container {
    height: 100vh;
    overflow-y: auto;
}

.page-container-menubar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: antiquewhite;
}

.page-header {
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0px 12px;
    gap: 8px;
    box-shadow: rgb(228, 230, 235) 0px -1px 0px inset;
    background-color: rgb(255, 255, 255);
    border-bottom: 1px solid #f3f3f3;
}

.page-header-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
}

.page-header-horizontal-menu {
    width: 100%;
}

.sider {
    overflow: auto;
    height: 100vh;
    left: 0;
    top: 0;
    bottom: 0;
}

.sider-toggle .ant-btn-icon {
    color: #fff;
}

.small-screen-trigger-button {
    font-size: 16px;
    width: 64px;
    height: 64px;
}

/* Padding for the content area */
.content-area {
    min-height: calc(100vh - 64px);
}

.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
}

.icon {
    height: 52px;
    width: auto;
}

.logout-item {
    position: absolute;
    bottom: 0;
    width: 100%;
}


/* Hide sidebar for small screens */
@media (max-width: 768px) {
    .content-area {
        padding: 0rem 0.5rem;
    }
}

/* Hide footer menu for large screens */
@media (min-width: 769px) {
    .content-area {
        padding: 1rem 2rem 2rem 2rem;
    }
}