.hero {
    padding: 0px 0px 20px 0;
}

.hero h1 {
    font-size: 2rem;
}

.hero p {
    font-size: 1.2rem;
}
