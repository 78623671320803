/* Deck.css */

.deck-container {
    margin: auto;
    margin-top: 2rem;
}

.loading-container {
    text-align: center;
    margin-top: 2rem;
}

.card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.study-card-container {
    overflow: hidden;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
}

.study-card-actions-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.study-recall-breakdown-container {
    margin-top: 10vh;
}

/* General button styles */
.study-card-action {
    font-weight: bold;
    font-size: 12px;
    padding: 10px 16px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.study-button {
    transition: background-color 0.3s ease;
}

/* Specific button colors */
.study-button.flip {
    background-color: #1677ff;
}

.study-button.flip:hover {
    background-color: #1f7cff;
    /* Darker hue for hover */
}

.study-button.blackout {
    background-color: rgba(0, 0, 0, 0.45);
    /* Lighter shade of black/gray */
}

.study-button.blackout:hover {
    background-color: rgba(0, 0, 0, 0.35);
    /* Slightly darker for hover */
}

.study-button.incorrect {
    background-color: #f57773;
    /* Lighter shade of red */
}

.study-button.incorrect:hover {
    background-color: #ff4c4c;
    /* Slightly darker for hover */
}

.study-button.correct {
    background-color: #5b61f6;
    /* Lighter shade of purple */
}

.study-button.correct:hover {
    background-color: #5b61f6;
    /* Slightly darker for hover */
}

.study-button.difficult {
    background-color: #009cff;
    /* Lighter shade of brown */
}

.study-button.difficult:hover {
    background-color: #099efa;
    /* Slightly darker for hover */
}

.study-button.easy {
    background-color: #00c9b0;
    /* Lighter shade of green */
}

.study-button.easy:hover {
    background-color: rgb(137, 250, 212);
    /* Slightly darker for hover */
}