/* Deck.css */

.deck-container {
    margin: auto;
    margin-top: 2rem;
}

.loading-container {
    text-align: center;
    margin-top: 2rem;
}

.card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.current-card {
    /* width: 50vw;
    height: 50vh; */
    width: 560px;
    height: 340px;
    margin-left: 2rem;
    margin-right: 2rem;
}
