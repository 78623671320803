.user-exam-cta-container {

}

.user-exam-cta-card {
    border-radius: 16px;
    margin: 16px;
    background-color: #faebd7;
}

.user-exam-cta-title {
}

.user-exam-cta-title-icon  {
    margin-right: 4px;
    color: #1677ff;
}

.user-exam-cta-content {
    height: 50px;
}

.user-exam-cta-footer {
    padding-top: 11px;
    display: flex;
    justify-content: flex-end;
}
