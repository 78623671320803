.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    padding: 5px 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
}

.icon {
    height: 52px;
    width: auto;
}
/* Adjustments for the buttons to ensure they appear next to each other */
button+button {
    margin-left: 10px;
}