.deck-card-image {
    height: 240px;
    width: auto;
    /* Maintain aspect ratio */
    object-fit: cover;
    /* Cover the whole area without distorting the image */
}

/* For small screens */
@media (max-width: 768px) {
    .deck-card-image {
        max-height: 120px;
    }
}