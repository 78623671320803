.card-face-container {
    background-color: #0958d936;
    border-radius: 8px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    position: relative;
    cursor: pointer;
    /* animated interactivity */
    /* transition: 250ms; */
    transition: transform 0.45s ease;
    transform-style: preserve-3d;
    transform: perspective(1000px) rotateY(var(--rotate-y, 0)) translateY(var(--translate-y, 0));
}

.card-face-container .adm-card {
    background-color: transparent;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* If this is not set yet */
}

.card-face-container .adm-card .adm-card-body {
    flex-grow: 1;
    /* This allows it to take up any available space */
}

.card-face-container .adm-card .adm-card-actions {
    /* background: rgba(0, 0, 0, 0.25); */
    border-top: 1px solid #f0f0f0;
}

.card-face {
    width: 100%;
    height: 100%;
}

.card-image-face .adm-card-body {
    padding: 0;
}

/* when card is clicked adding .flipped class */
.card-face-flipped {
    background-color: #ff4d4f47;
    transform: rotateY(-180deg);
}

/* card content */
.card-face-container .card-question-face,
.card-face-container .card-answer-face {
    position: absolute;
    backface-visibility: hidden;
}

.card-face-container .card-question-face {
    transform: rotateY(0deg);
}

.card-face-container.flipped .card-question-face {
    opacity: 0;
    display: none;
}

.card-face-container .card-answer-face {
    transform: rotateY(180deg);
}

.card-face-container .adm-card-cover {
    overflow: hidden;
}

.card-image {
    height: 100%;
    width: 100%;
    /* Maintain aspect ratio */
    object-fit: cover;
    /* Cover the whole area without distorting the image */
}

.center-large-text,
.center-large-text .adm-card-meta-detail {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.6rem; /*calc(32px + 0.5vw);*/
    margin: auto;
    width: 100%;
}

.center-large-text .adm-card-meta-title {
    text-align: center;
    font-size: 1.6rem; /*calc(32px + 0.5vw);*/
    margin: auto;
    white-space: wrap;
    text-overflow: unset;
    padding: 0.5rem;
}